import React, { memo } from 'react'
import PropTypes from 'prop-types'

import Slider from '@material-ui/core/Slider'
import { withStyles } from '@material-ui/core/styles'

import Thumb from '../assets/thumb.svg'

import { Title, Item, ThumbImage, Content } from '../views'

const StyledSlider = withStyles((theme) => ({
  root: {
    color: (props) => props.defaultColor || theme.palette.black.light,
    height: theme.spacing(2)
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: 'transparent',
    marginTop: theme.spacing(-1),
    marginLeft: theme.spacing(-3),
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  mark: {
    height: 16,
    width: 1,
    backgroundColor: theme.palette.white.main,
  },
  markLabel: {
    marginTop: theme.spacing(3),
    fontFamily: "Poppins, serif"
  },
  track: {
    height: 16,
    borderRadius: 3,
    backgroundColor: (props) => props.activeColor || theme.palette.primary.main,
  },
  rail: {
    height: 16,
    borderRadius: 3,
  },
  button: {
    fontFamily: "Poppins, serif"
  }
}))(Slider)

/**
 * DisplayValue
 * @param {string} value
 */
const displayValue = (value) => {
  return `${value}k`
}

/**
 * Get max number of slider by marks
 * @param {array} marks - array of marks
 */
const getMaxNumberSliderByMarks = (marks) => {
  if (marks?.length === 0) {
    return 0
  } else {
    return marks[marks.length - 1]?.value
  }
}

const ThumbComponent = (props) => {
  return (
    <div {...props}>
      <ThumbImage src={Thumb} />
    </div>
  )
}

const SliderComponent = ({
  defaultValue,
  marks,
  setCurrentValue,
  defaultColor,
  activeColor,
  steps,
  title,
  currentValue,
}) => {
  return (
    <Item className='pl-3 pr-3' item xs={10} borderRight>
      <Content className='pr-4'>
        <Title variant='h3'>{title}</Title>
        <StyledSlider
          onChange={(_, number) => setCurrentValue(number)}
          defaultValue={defaultValue}
          getAriaValueText={displayValue}
          aria-labelledby='discrete-slider'
          valueLabelDisplay='auto'
          value={currentValue}
          step={steps}
          min={marks[0].value}
          max={getMaxNumberSliderByMarks(marks)}
          defaultColor={defaultColor}
          activeColor={activeColor}
          marks={marks}
          ThumbComponent={ThumbComponent}
        />
      </Content>
    </Item>
  )
}

SliderComponent.propTypes = {
  activeColor: PropTypes.string.isRequired,
  defaultValue: PropTypes.number.isRequired,
  defaultColor: PropTypes.string.isRequired,
  setCurrentValue: PropTypes.func.isRequired,
  steps: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  marks: PropTypes.array.isRequired,
}

export default memo(SliderComponent)
