import React, { useState, useEffect } from 'react'
import Header from './pricing-header.styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const PricingHeader = ({
  showHeader,
  setSelectedPlan,
  setExpanded,
  expanded,
}) => {
  const [doShow, setDoShow] = useState(false)

  useEffect(() => {
    setDoShow(showHeader)
  }, [showHeader])

  return (
    <Header showHeader={doShow} expanded={expanded}>
      <Header.Grid>
        <Header.Compare onClick={() => setExpanded(false)}>
          <ExpandMoreIcon size='inherit' />
          Compare All Features
        </Header.Compare>
        <Header.Item>
          Ultimate
          <br />
          <Header.Button
            href='#calculate'
            onClick={() => setSelectedPlan('ultimate')}
          >
            Calculate Your Price
          </Header.Button>
        </Header.Item>
        <Header.Item>
          Awesome
          <br />
          <Header.Button
            href='#calculate'
            onClick={() => setSelectedPlan('awesome')}
          >
            Calculate Your Price
          </Header.Button>
        </Header.Item>
        <Header.Item>Free</Header.Item>
      </Header.Grid>
    </Header>
  )
}

export default PricingHeader
