import styled, { css } from 'styled-components'
import { flexbox, grid, position, layout, space } from 'styled-system'

import { theme, media } from '../../helpers/theme'

const FeatureActiveCard = css`
  ${({ isActive }) =>
    isActive
      ? `
        border: 3px solid ${theme.colors.primary};
        border-top: unset;
        position: relative;
        box-shadow: unset;
        &:after {
          content: '';
          position: absolute;
          transform: translate(0, 100%);
          bottom: 0;
          left: calc(50% - 40px);
          width: 0;
          height: 0;
          border-left: 40px solid transparent;
          border-right: 40px solid transparent;
          border-top: 30px solid ${theme.colors.primary};
        }
        `
      : ''}
`

const FeatureBorder = css`
  ${({ border }) =>
    border
      ? `
      border-radius: 0px 0px 4px 4px;
      border: 3px solid rgba(0, 0, 0, 0.1);
      box-sizing: border-box;
      border-top: unset;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      box-shadow: 0px 2px 2px 2px rgba(143, 142, 135, 0.1);
      `
      : ``}
`

const FeatureActiveState = css`
  ${({ isActive }) =>
    isActive
      ? `
          background-color: ${theme.colors.darkPurple};
          color: ${theme.colors.font.white};
        `
      : `
          background-color: ${theme.colors.lightGray};
          color: ${theme.colors.darkPurple}; 
        `}

  ${({ isMarketingColumn }) =>
    isMarketingColumn
      ? `
      grid-template-columns: minmax(0, 1fr);
      background-image: linear-gradient(to right, transparent 35%, #F9F9F9 35%);
      background-color: transparent;
      color: ${theme.colors.font.lightGray};
      .list-item {
        font-weight: normal;
      }
    `
      : ''}
`

// 2 rows x 4 columns
const PricingSection = styled.section`
  position: relative;
  transition: all 1s;
  display: grid;
  justify-content: center;
  grid-column-gap: 8px;
  grid-template-columns: minmax(250px, 350px) repeat(3, minmax(150px, 310px));
  grid-template-rows: 50px 30px repeat(2, min-content);
  padding: ${theme.space[1]}px;
  margin-bottom: ${theme.space[16]}px;
  overflow: unset;
  transform: translateX(-16px);
  ${media('xl')} {
    overflow: visible;
  }
`

PricingSection.cardContainer = styled.div`
  grid-row: 1;
  /* border-bottom: 1.35504px solid rgba(0, 0, 0, 0.05); */
  justify-content: flex-start;
  position: relative;
  ${grid}
  ${flexbox}
  ${position}
  ${layout}
`

PricingSection.tableToggle = styled.div`
  align-items: flex-end;
  background: white;
  display: flex;
  grid-row: 1;
`

PricingSection.titleSpan = styled.span`
  border-bottom: 2px solid #d8cff0;
  height: 42px;
  background-color: #f3f0fb;
  position: absolute;
  left: -100vw;
  width: 1000%;
`

PricingSection.emailMarketingTitle = styled.span`
  color: ${theme.colors.font.darkGray};
  font-size: ${theme.font.lg};
  font-weight: bold;
  width: 100%;
  padding: ${theme.space[2]}px;
  position: relative;
  cursor: pointer;

  ${({ expanded }) =>
    expanded
      ? `
      display: flex;
      align-items: center
    `
      : `
      svg {
        transform: rotate(180deg) translateY(-25%);
      }
    `}
`

PricingSection.featuresWrapper = styled.div`
  grid-row: 2;
  padding-right: 8px;
  ${flexbox}
  ${grid}
  ${position}
  `

PricingSection.featuresContainer = styled.div`
  display: grid;
    ${({ rows = 7 }) => `grid-template-rows: repeat(${rows}, 45px) 25px;`}
  flex-direction: column;
  grid-auto-flow: column;
  grid-row: 2;
  width: 100%;
  
  .list-item {
    border-bottom: 1.35504px solid rgba(0, 0, 0, 0.05);
    display: flex;
    height: 100%;
    align-items: center;
    ${({ justifyCenter }) => (justifyCenter ? 'justify-content: center;' : '')}
  }
  
${FeatureActiveState}
${FeatureBorder}
${FeatureActiveCard}

${flexbox}
${grid}
${position}
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 300px;

  ${space}
  ${flexbox}
  ${layout}
`

export { Row, Column }

export default PricingSection
