import React from 'react'

import { FREE_PLANS_FEATURES } from './../../pricing-section/pricing-section.constants'

import { FeatureText } from './../pricing-card.styles'
import {
  DesktopFeaturesContainer,
  UnExpandedFeaturesList
} from './feature-list.styles'

const FreeSmallList = ({ setSelectedPlan = () => {}, isActive, ...rest }) => {
  return (
    <DesktopFeaturesContainer
      border
      isActive={isActive}
      onClick={setSelectedPlan}
      rows={1}
      {...rest}
    >
      <UnExpandedFeaturesList>
        {FREE_PLANS_FEATURES.map((feature) => (
          <FeatureText key={feature}>{feature}</FeatureText>
        ))}
      </UnExpandedFeaturesList>
    </DesktopFeaturesContainer>
  )
}

export default FreeSmallList
