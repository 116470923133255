import { Localizations } from '@eig-builder/module-localization'

Localizations.append({
  compositions: {
    pricingSliderCalculator: {
      totalPrice: 'Total price',
      perMonth: '/ mo'
    }
  }
})
