import React from 'react'
import styled from 'styled-components'

import { FREE_PLAN_FEATURES_MOBILE } from '../../pricing-section/pricing-section.constants'

import {
  FeaturesContainer,
  FeaturesList,
  FeatureText,
} from '../pricing-card.styles'

const [
  intelligentEmailEditor,
  wordPressBlobAndProduct,
  woocomerceIntegration,
  contactsCrm,
  campaignScheduler,
  phoneSupport,
  multistepAutomation,
  contacts,
] = FREE_PLAN_FEATURES_MOBILE

const FreePlanFeaturesContainer = styled(FeaturesContainer)`
  ${FeaturesList} {
    justify-content: center;
  }
`

const FreeList = ({ className, setSelectedPlan = () => {}, isActive }) => (
  <FreePlanFeaturesContainer
    border
    className={`${className} ${isActive ? 'active' : ''}`}
    isActive={isActive}
    onClick={setSelectedPlan}
  >
    <FeaturesList>
      <FeatureText>{intelligentEmailEditor}</FeatureText>
    </FeaturesList>
    <FeaturesList>
      <FeatureText>{wordPressBlobAndProduct}</FeatureText>
    </FeaturesList>
    <FeaturesList>
      <FeatureText _comingSoonBefore>{woocomerceIntegration}</FeatureText>
    </FeaturesList>
    <FeaturesList>
      <FeatureText>{contactsCrm}</FeatureText>
    </FeaturesList>
    <FeaturesList>
      <FeatureText _perDay>{contacts}</FeatureText>
    </FeaturesList>
    <FeaturesList>
      <FeatureText _strikeThrough>{campaignScheduler}</FeatureText>
    </FeaturesList>
    <FeaturesList>
      <FeatureText _strikeThrough>{phoneSupport}</FeatureText>
    </FeaturesList>
    <FeaturesList>
      <FeatureText _strikeThrough>{multistepAutomation}</FeatureText>
    </FeaturesList>
  </FreePlanFeaturesContainer>
)

export default FreeList
