import React from 'react'

import LinkSection from '../link-section'

import SmallWave from './assets/small-wave.svg'
import SmallWaveMobile from './assets/small-wave-mobile.svg'
import Footer from './footer.style'

const FooterSection = () => (
  <Footer>
    <Footer.mobileWave className='wave' src={SmallWaveMobile} />
    <Footer.wave className='wave' src={SmallWave} />
    <Footer.linkSection>
      <LinkSection />
    </Footer.linkSection>
  </Footer>
)

export default FooterSection
