import styled from 'styled-components'
import theme, { media } from '../../helpers/theme'

const PricingHeader = styled.div`
  width: 100%;
  height: 76px;
  background-color: #f3f0fb;
  position: fixed;
  top: ${(props) => (props.showHeader && props.expanded ? '0px' : '-76px')};
  border-bottom: 4px solid #d8cff0;
  left: 0;
  z-index: 100;
  display: none;
  transition: top 0.3s;
  box-shadow: ${(props) =>
    props.showHeader && props.expanded
      ? '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)'
      : 'none'};
  ${media('xl')} {
    display: block;
  }
`
PricingHeader.Grid = styled.div`
  height: 100%;
  display: grid;
  grid-column-gap: 8px;
  grid-template-columns: minmax(250px, 350px) repeat(3, minmax(150px, 310px));
  justify-content: center;
  transform: translateX(-16px);
`

PricingHeader.Item = styled.div`
  text-align: center;
  color: ${theme.colors.font.darkGray};
  font-size: ${theme.font['md']};
  font-weight: bold;
  padding-top: ${theme.space[2]}px;
  width: 100%;
  height: 100%;
`
PricingHeader.Compare = styled.div`
  align-items: center;
  display: inline-flex;
  color: ${theme.colors.font.darkGray};
  font-size: ${theme.font.lg};
  font-weight: bold;
  padding: ${theme.space[2]}px;
  padding-top: ${theme.space[4]}px;
  width: 100%;
  height: 30px;
  &:hover {
    cursor: pointer;
  }
`

PricingHeader.Button = styled.a`
  background-color: ${theme.colors.font.black};
  border-radius: 4px;
  margin-top: ${theme.space[2]}px;
  padding: ${theme.space[1]}px;
  color: ${theme.colors.font.white};
  display: inline-block;
  font-size: ${theme.font['xs']};
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
`

export default PricingHeader
