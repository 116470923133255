import React from 'react'

import {
  AWESOME_PLANS_FEATURES_EXPANDED,
  AWESOME_PLANS_FEATURES,
} from '../../pricing-section/pricing-section.constants'

import { FeaturesList, FeatureText, Done, Close } from '../pricing-card.styles'
import {
  DesktopFeaturesContainer,
  UnExpandedFeaturesList,
} from './feature-list.styles'

const AwesomeDesktopList = ({
  setSelectedPlan = () => {},
  isActive,
  expanded,
  ...rest
}) => {
  const features = expanded ? AWESOME_PLANS_FEATURES_EXPANDED : ['']

  return (
    <DesktopFeaturesContainer
      border
      isActive={isActive}
      onClick={setSelectedPlan}
      rows={features.length}
      expanded={expanded}
      {...rest}
    >
      <UnExpandedFeaturesList>
        {AWESOME_PLANS_FEATURES.map(({ label, modifiers }) => (
          <FeatureText key={label} {...modifiers}>
            {label}
          </FeatureText>
        ))}
      </UnExpandedFeaturesList>
      {expanded &&
        features.map((feature, i) => (
          <FeaturesList
            key={`awesome-${feature}-${i}`}
            header={feature === null}
            isActive={isActive}
          >
            <FeatureText>
              {feature === true ? (
                <Done />
              ) : feature === false ? (
                <Close isActive={isActive} />
              ) : (
                feature
              )}
            </FeatureText>
          </FeaturesList>
        ))}
    </DesktopFeaturesContainer>
  )
}

export default AwesomeDesktopList
