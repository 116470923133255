export const FREE_PLAN_FEATURES = ['1,000', '5,000', '100', false, false, false]

export const PLAN_TYPES = ['ultimate', 'awesome', 'free']

export const FREE_PLAN_FEATURES_MOBILE = [
  'Intelligent Email Editor',
  'WordPress Blog & Product Promotion',
  'WooCommerce Triggered Emails',
  'Contacts CRM',
  'Campaign Scheduler',
  'Phone Support',
  'Multi-Step Automations',
  '1,000 Contacts',
]

export const FREE_PLANS_FEATURES = [
  'WordPress admin management',
  'AI-driven email creation',
  'WordPress blog content',
  'WooCommerce product promotion',
  'Ideal for getting started in WordPress marketing',
]

export const FREE_PLANS_FEATURES_EXPANDED = [
  null,
  true,
  true,
  null,
  true,
  true,
  true,
  null,
  true,
  true,
  true,
  '2',
  null,
  true,
  false,
  null,
  true,
  true,
  true,
  true,
  true,
  true,
  false,
  null,
  true,
  true,
  false,
  true,
  false,
  false,
  null,
  false,
  false,
  false,
  null,
  true,
  false,
  false,
  null,
  '1,000',
  '5,000',
  '100',
]

export const ULTIMATE_PLANS_FEATURES_EXPANDED = [
  null,
  true,
  true,
  null,
  true,
  true,
  true,
  null,
  true,
  true,
  true,
  'Unlimited',
  null,
  true,
  true,
  null,
  true,
  true,
  true,
  true,
  true,
  true,
  true,
  null,
  true,
  true,
  true,
  true,
  true,
  true,
  null,
  'Unlimited',
  true,
  'Unlimited',
  null,
  true,
  true,
  true,
  null,
  '100,000',
  'Unlimited',
  '10,000',
]

export const AWESOME_PLANS_FEATURES = [
  { label: 'All the Free features, plus:', modifiers: {} },
  { label: 'WooCommerce Single-Step automations', modifiers: {} },
  { label: 'Advanced Multi-Step Automations', modifiers: {} },
  { label: 'Chat & Email support', modifiers: {} },
  { label: 'Custom & larger list support', modifiers: {} },
  { label: 'Perfect for growing businesses or bloggers', modifiers: {} },
]

export const AWESOME_PLANS_FEATURES_EXPANDED = [
  null,
  true,
  true,
  null,
  true,
  true,
  true,
  null,
  true,
  true,
  true,
  '10',
  null,
  true,
  true,
  null,
  true,
  true,
  true,
  true,
  true,
  true,
  true,
  null,
  true,
  true,
  true,
  true,
  true,
  true,
  null,
  'Unlimited',
  true,
  '2',
  null,
  true,
  true,
  true,
  null,
  '27,500',
  '45,000',
  '1,000',
]

export const FREE_PLAN_HEADER = {
  plan: 'Free',
  sku: 'PLAN0_CE',
  info: [
    'Grow your business with the basics. Experience the value of e-mail campaigns.',
  ],
}

export const AWESOME_PLAN_FEATURES = [
  'Unlimited',
  '45,000',
  '1,000',
  true,
  false,
  true,
]

export const AWESOME_PLAN_FEATURES_MOBILE = [
  'Intelligent Email Editor',
  'WordPress Blog & Product Promotion',
  'WooCommerce Triggered Emails',
  'Contacts CRM',
  'Campaign Scheduler',
  'Phone Support',
  'Multi-Step Automations',
  'Unlimited Contacts',
]

export const AWESOME_PLAN_HEADER = {
  plan: 'Awesome',
  sku: 'PLAN1_CE',
  info: ['Starting from', '4.95', '6.00'],
}

export const ULTIMATE_PLAN_FEATURES = [
  'Unlimited',
  'Unlimited',
  'Unlimited',
  true,
  true,
  true,
]

export const ULTIMATE_PLANS_FEATURES = [
  { label: 'All the Awesome features, plus:', modifiers: {} },
  { label: 'More contacts', modifiers: {} },
  { label: 'Advanced Multi-Step Automations', modifiers: {} },
  { label: 'Premium phone support', modifiers: {} },
  { label: 'Largest list sizes', modifiers: {} },
  { label: 'Perfect for high-volume ecommerce sellers', modifiers: {} },
]

export const ULTIMATE_PLAN_FEATURES_MOBILE = [
  'All the Awesome features, plus:',
  'More contacts',
  'Advanced Multi-Step Automations',
  'Premium phone support',
  'Largest list sizes',
  'Perfect for high-volume ecommerce sellers',
]

export const ULTIMATE_PLAN_HEADER = {
  plan: 'Ultimate',
  sku: 'PLAN2_CE',
  info: ['Starting from', '10.95', '14.95'],
}

export const EMAIL_MARKETING_FEATURES = 'Email Marketing Features'

const TOTAL_CONTACTS = 'Total Contacts'

const UNIQUE_EMAILS_PER_MONTH = 'Unique E-mails per Month'

const CONTACTS_UPLOAD_PER_DAY = 'Contacts Uploaded per Day'

const SCHEDULE_CAMPAIGNS = 'Schedule Campaigns'

const TRIGGERED_EMAILS = 'Triggered E-mails'

const WOOCOMMERCE_INTEGRATION = 'WooCommerce Integration'

export const ALL_MARKETING_FEATURES = [
  { label: TOTAL_CONTACTS },
  { label: UNIQUE_EMAILS_PER_MONTH },
  { label: CONTACTS_UPLOAD_PER_DAY },
  { label: SCHEDULE_CAMPAIGNS },
  { label: TRIGGERED_EMAILS },
  { label: WOOCOMMERCE_INTEGRATION },
]

export const EXPANDED_MARKETING_FEATURES = [
  { label: 'Superior Deliverability', header: true },
  {
    label: 'Auto Fraud Trap Screening',
    header: false,
    tooltip:
      "We review your email contacts to make sure the emails you've provided aren't from nefarious sources hurting your deliverability.",
  },
  {
    label: 'Simplified Opt-Out Management',
    header: false,
    tooltip:
      "Make opt-out easy with our leading opt-out and unsubscribe management tools.",
  },
  { label: 'WordPress Connectivity', header: true },
  {
    label: 'WordPress Form Automatic List Builder',
    header: false,
    tooltip:
      "No need to add javascript, we'll automatically pull in your contact  emails from the top WordPress forms.",
  },
  {
    label: 'Blog Post Content Syndication',
    header: false,
    tooltip:
      'We automatically pull in your blog posts as content you can share in your emails.',
  },
  { label: 'WooCommerce Shoppable Products', header: false },
  { label: 'Included CRM', header: true },
  {
    label: 'Creative CRM',
    header: false,
    tooltip: 'We include Creative CRM as part of your email solution.',
  },
  {
    label: 'Contact Activity Map',
    header: false,
    tooltip:
      'View where your customers open your email on our visual map display.',
  },
  {
    label: 'Contact Origin',
    header: false,
    tooltip:
      "Did someone fill out a website form or did they buy a product from your store? You'll know with our contact origin.",
  },
  { label: 'Custom Audience Lists', header: false },
  { label: 'Audience Insights', header: true },
  {
    label: 'Visual Reports',
    header: false,
    tooltip:
      'Get powerful and easy to read stats on opens, clicks, bounces and more.',
  },
  {
    label: 'Geographic Insights',
    header: false,
    tooltip: 'Know where your brand message is resonating geographically.',
  },
  { label: 'Brand & Design Tools', header: true },
  {
    label: 'Mobile Friendly Editor',
    header: false,
    tooltip: 'Create email campaigns on your phone.',
  },
  {
    label: 'Image Studio',
    header: false,
    tooltip:
      'Edit your images or those from the Unsplash library for the perfect visual impact.',
  },
  {
    label: 'LogoMaker',
    header: false,
    tooltip: 'No need to hire a graphic designer for logos.',
  },
  {
    label: 'AI-Created Templates',
    header: false,
    tooltip:
      'Our intelligent editor creates a set of super fast, visually personalized templates that you can then edit if you want.',
  },
  {
    label: 'Unsplash + WordPress Image Library',
    header: false,
    tooltip:
      'Access images from your WordPress website library or use the award winning pictures from Unsplash in your emails.',
  },
  { label: 'Unlimited Image Storage', header: false },
  {
    label: 'Remove Creative Viral Footer',
    header: false,
    tooltip: 'Remove Creative Mail branded footer with our paid plans.',
  },
  { label: 'Marketing Channels', header: true },
  {
    label: 'High Volume Email',
    header: false,
    tooltip:
      'Our engine supports high volume and high delivery of your emails.',
  },
  {
    label: 'WordPress Blog Article Syndication',
    header: false,
    tooltip:
      'Syndicate blog posts by sending email customers directly to your latest content.',
  },
  {
    label: 'Social Posting',
    header: false,
    tooltip: 'Send posts to complement your emails to your social followers.',
  },
  {
    label: 'Google My Business',
    header: false,
    tooltip:
      'Get listed on Google My Business, send posts or create ads for your Google Business Location.',
  },
  { label: 'My Marketing Calendar', header: false },
  {
    label: 'Appointment Booking',
    header: false,
    tooltip:
      'Send invitations to your customers and book business or service appointments.',
  },
  { label: 'Automations', header: true },
  { label: 'Email Campaign Scheduling', header: false },
  {
    label: 'Single-Step WooCommerce Automations',
    header: false,
    tooltip: 'Create one-off, triggered emails for your WooCommerce store.',
  },
  {
    label: 'Multi-Step Automations',
    header: false,
    tooltip:
      'Build multi-step automations for elaborate and targeted email campaigns.',
  },
  { label: 'Support', header: true },
  { label: 'Online help articles', header: false },
  { label: '24/7 Email & Chat', header: false },
  { label: 'Phone Support', header: false },
  { label: 'Limits', header: true },
  { label: 'Total Contacts', header: false },
  { label: 'Monthly Email Sends', header: false },
  { label: 'Contact Uploads per Day', header: false },
]

export const STARTING_ROWS = {
  awesome: '1 / 4',
  ultimate: '2 / 4',
  free: '3 / 4',
}
