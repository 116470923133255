import React, { useState } from 'react'
import styled from 'styled-components'

import SEO from './../components/seo'
import Layout from './../components/layout'
import Footer from '../components/footer-pricing'
import PlansHero from '../components/plans-hero'
import PricingSection from '../components/pricing-section'
import ContactsSection from '../components/contacts-section'

import './../style/bootstrap.scss'
import theme from '../helpers/theme'

const Disclaimer = styled.div`
  max-width: ${theme.breakpoints.md}px;
  margin: 0 auto;
  padding: ${theme.space[8]}px;
`

const Plans = () => {
  const [selectedPlan, setSelectedPlan] = useState('awesome')

  return (
    <Layout isWhiteLogo>
      <SEO
        title='Email marketing for WordPress'
        pageType='home'
        flowType='none'
        path='plans'
      />
      <PlansHero />
      <PricingSection
        selectedPlan={selectedPlan}
        setSelectedPlan={setSelectedPlan}
      />
      <ContactsSection selectedPlan={selectedPlan} />
      <Disclaimer>
        <span>
        * For customers in regions in which VAT (Value Added Tax) or GST (Goods and Services Tax) is applicable, taxes are not included in our advertised price and will be charged separately and itemized on invoices and billing information. Pricing is calculated monthly based on the highest number of active contacts in the previous month. Maximum email sends per mo: Free 5,000, Awesome 45,000, Ultimate unlimited.
        </span>
      </Disclaimer>
      <Footer />
    </Layout>
  )
}

export default Plans
