import { useEffect, useRef, useState } from 'react'

const useIntersect = ({ root = null, rootMargin, threshold = 0 } = {}) => {
  const [entry, updateEntry] = useState({})
  const [node, setNode] = useState(null)

  const observer = useRef(null)

  useEffect(() => {
    if (!window || typeof window === 'undefined') return
    observer.current = new window.IntersectionObserver(([entry]) => updateEntry(entry), {
      root,
      rootMargin,
      threshold
    })
    const { current: currentObserver } = observer
    currentObserver.disconnect()

    if (node) currentObserver.observe(node)

    return () => currentObserver.disconnect()
  }, [node, observer, root, rootMargin, threshold])

  return [setNode, entry]
}

export default useIntersect
