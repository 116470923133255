import styled, { keyframes, css } from 'styled-components'
import SwipeableViews from 'react-swipeable-views'

import {
  AwesomeHeader,
  FreeHeader,
  UltimateHeader,
  AwesomeList,
  FreeList,
  UltimateList
} from './../../pricing-card'
import { FeaturesList } from './../../pricing-card/pricing-card.styles'

import { theme } from './../../../helpers/theme'

const slideRight = keyframes`
  0% {
    right: 0px;
  }
  100% {
    right: -20px;
  }
`

const slideLeft = keyframes`
  0% {
    left: 0px;
  }
  100% {
    left: -20px;
  }
`

const cardSlideRight = css`
  transition-timing-function: ease-in-out;
  animation-name: ${slideRight};
  animation-duration: 0.55s;
  animation-delay: 0.15s;
`

const cardSlideLeft = css`
  transition-timing-function: ease-in-out;
  animation-name: ${slideLeft};
  animation-duration: 0.55s;
  animation-delay: 0.15s;
`

export const StyledSwipeableViews = styled(SwipeableViews)`
  div > div {
    position: relative;
    overflow: unset !important;
  }

  .react-swipeable-view-container {
    height: 750px;
  }

  /* RIGHT CARD ACTIVE */
  .center-card:not(.active) {
    z-index: 0;
    position: absolute;
    right: -20px;
    ${cardSlideRight}
  }

  div > .right-card.active {
    z-index: 1;
    position: relative;
    right: unset;
    left: unset;
    transition: left 0.55s right 0.055s;
    transition-timing-function: ease-in;
  }

  /* CENTER CARD ACTIVE */
  .left-card:not(.active) {
    z-index: 0;
    position: absolute;
    right: -20px;
    ${cardSlideRight}
  }

  .right-card:not(.active) {
    z-index: 0;
    position: absolute;
    left: -20px;
    ${cardSlideLeft}
  }

  div > .center-card.active {
    z-index: 1;
    position: relative;
    left: unset;
    right: unset;
    transition: right 0.55s left 0.55s;
    transition-timing-function: ease-in;
  }

  /* LEFT-CARD-ACTIVE */
  .center-card.left-active {
    z-index: 0;
    position: absolute;
    left: -20px;
    right: unset;
    ${cardSlideLeft}
  }

  div > .left-card.active {
    z-index: 1;
    position: relative;
    right: unset;
    transition: right 0.55s;
    transition-timing-function: ease-in;
  }

  ${FeaturesList} {
    padding: ${theme.space[3]}px;
  }
`

StyledSwipeableViews.ultimateHeader = styled(UltimateHeader)``

StyledSwipeableViews.awesomeHeader = styled(AwesomeHeader)``

StyledSwipeableViews.freeHeader = styled(FreeHeader)``

StyledSwipeableViews.ultimateList = styled(UltimateList)`
  display: flex;
  flex-direction: column;
  padding: ${theme.space[2]}px 0;
  width: 100%;
`

StyledSwipeableViews.awesomeList = styled(AwesomeList)`
  display: flex;
  flex-direction: column;
  padding: ${theme.space[2]}px 0;
  width: 100%;
`

StyledSwipeableViews.freeList = styled(FreeList)`
  display: flex;
  flex-direction: column;
  padding: ${theme.space[2]}px 0;
  width: 100%;
`
