import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { theme, media } from './../../helpers/theme'
import { useIntersect } from './../../helpers/hooks'

import Desktop from './desktop'
import Small from './small'
import Mobile from './mobile'
import PricingHeader from '../pricing-header'

const MobileView = styled(Mobile)`
  display: none;
`

const SmallView = styled(Small)`
  display: none;
`

const DesktopView = styled(Desktop)`
  display: none;
`

const ViewManager = styled.div`
  margin-bottom: ${theme.space[16]}px;
  overflow: visible;
  ${MobileView} {
    display: block;
  }
  ${media('md')} {
    ${SmallView} {
      display: flex;
    }
    ${MobileView} {
      display: none;
    }
  }

  ${media('xl')} {
    margin-bottom: 0;
    overflow: hidden;
    ${DesktopView} {
      display: grid;
    }
    ${SmallView} {
      display: none;
    }
    ${MobileView} {
      display: none;
    }
  }
`

const PricingSectionComponent = ({ selectedPlan, setSelectedPlan }) => {
  const [showHeader, setShowHeader] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const [ref, entry] = useIntersect()
  const viewRef = useRef(null)
  const [heightStart, setHeightStart] = useState(0)

  const doExpand = (val) => {
    if (viewRef.current && typeof viewRef.current !== 'undefined' && heightStart === 0) {
      const height = viewRef.current.scrollHeight
      viewRef.current.style.height = `${height}px`
      setHeightStart(height)
    } 
    setExpanded(val)
  }

  useEffect(() => {
    if (!viewRef.current || typeof viewRef.current === 'undefined') return
    if (expanded) {
      const height = viewRef.current.scrollHeight
      viewRef.current.style.height = `${height}px`
    } else if (heightStart > 50) {
      viewRef.current.style.height = `${heightStart}px`
    }
  }, [expanded, heightStart])
  useEffect(() => {
    if (
      entry?.boundingClientRect?.top + entry?.boundingClientRect?.height <
      0
    ) {
      setShowHeader(true)
    } else {
      setShowHeader(false)
    }
  }, [entry])

  return (
    <>
      <PricingHeader
        showHeader={showHeader}
        setSelectedPlan={setSelectedPlan}
        expanded={expanded}
        setExpanded={doExpand}
      />
      <ViewManager>
        <DesktopView
          selectedPlan={selectedPlan}
          setSelectedPlan={setSelectedPlan}
          pricingRef={ref}
          expanded={expanded}
          setExpanded={doExpand}
          viewRef={viewRef}
        />
        <SmallView
          selectedPlan={selectedPlan}
          setSelectedPlan={setSelectedPlan}
        />
        <MobileView
          selectedPlan={selectedPlan}
          setSelectedPlan={setSelectedPlan}
        />
      </ViewManager>
    </>
  )
}

PricingSectionComponent.props = {
  children: PropTypes.node,
}

export default PricingSectionComponent
