import React, { memo } from 'react'
import PropTypes from 'prop-types'

import Grid from '@eig-builder/core-ui/Grid'
import Text from '@eig-builder/module-localization'

import { Price, PerTypo, Monthly, PoppinTypography } from '../views'


/**
 * Get Price by current value
 * @param {array} marks
 * @param {int} currentValue
 */
const getPrice = (marks, value) => {
  const item = marks.find((item) => item.value === value)
  return item?.price
}

const PriceInfo = ({ value, currency, marks, color }) => {
  return (
    <Grid item xs={6}>
      <Grid container direction='row'>
        <PoppinTypography variant='h5'>
          <Text message='compositions.pricingSliderCalculator.totalPrice' />
        </PoppinTypography>
        <Grid item className='px-4'>
          <Grid container direction='row'>
            <Price color={color}>
              {currency} {getPrice(marks, value)}
            </Price>
            <PerTypo variant='h5'>*</PerTypo>
            <Monthly variant='h5'>
              <Text message='compositions.pricingSliderCalculator.perMonth' />
            </Monthly>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

PriceInfo.propTypes = {
  currency: PropTypes.string.isRequired,
  value: PropTypes.number,
  marks: PropTypes.arrayOf(PropTypes.object).isRequired,
  color: PropTypes.string.isRequired,
}

export default memo(PriceInfo)
