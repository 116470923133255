import React, { useState, useEffect } from 'react'
import { DataElementLocations } from '@eig-builder/core-utils/helpers/tagging-helper'
import Layout from './pricing-dropdown.styles'

const PricingDropdown = ({ defaultValue, marks }) => {
  const [price, setPrice] = useState(defaultValue)

  useEffect(() => {
      setPrice([...marks].shift()?.price)
  }, [marks])

  const handleChange = (e) => {
    setPrice(marks.find((m) => m.value === parseInt(e.target.value))?.price)
  }

  return (
    <Layout>
      <Layout.title>How many Contacts will you have?</Layout.title>

      <Layout.select onChange={handleChange} defaultValue={defaultValue}>
        {marks.map((mark) => (
          <option key={`dropdown-val-${mark.value}-${mark.price}`} value={mark.value}>
            {mark.value.toLocaleString()} Contacts
          </option>
        ))}
      </Layout.select>

      <Layout.priceContent>
        Total Price <Layout.price>${price}</Layout.price>
        <Layout.asterisk variant='h5'>*</Layout.asterisk>
        <Layout.monthly variant='h5'>/ mo</Layout.monthly>
      </Layout.priceContent>

      <Layout.cta
        dataElementLocation={DataElementLocations.BODY}
        dataElementLabel='plans.pricingDropdown.cta'
        dataElementId='creativemail-plans-pricing-dropdown-cta'
        href={process.env.GATSBY_PLUGIN_URL}
      >
        Start Free
      </Layout.cta>
    </Layout>
  )
}

export default PricingDropdown
