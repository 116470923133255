import React from 'react'
import styled from 'styled-components'

import { ULTIMATE_PLAN_FEATURES_MOBILE } from '../../pricing-section/pricing-section.constants'

import {
  FeaturesContainer,
  FeaturesList,
  FeatureText,
} from '../pricing-card.styles'

const [
  totalContacts,
  uniqueEmails,
  contactsUploaded,
  scheduleCampaigns,
  triggeredEmails,
  woocomerceIntegration,
] = ULTIMATE_PLAN_FEATURES_MOBILE

const UltimatePlanFeaturesContainer = styled(FeaturesContainer)`
  ${FeaturesList} {
    justify-content: center;
  }
`

const UltimateList = ({ className, setSelectedPlan = () => {}, isActive }) => (
  <UltimatePlanFeaturesContainer
    border
    className={`${className} ${isActive ? 'active' : ''}`}
    isActive={isActive}
    onClick={setSelectedPlan}
  >
    <FeaturesList>
      <FeatureText>{totalContacts}</FeatureText>
    </FeaturesList>
    <FeaturesList>
      <FeatureText>{uniqueEmails}</FeatureText>
    </FeaturesList>
    <FeaturesList>
      <FeatureText>{contactsUploaded}</FeatureText>
    </FeaturesList>
    <FeaturesList>
      <FeatureText>{scheduleCampaigns}</FeatureText>
    </FeaturesList>
    <FeaturesList>
      <FeatureText>{woocomerceIntegration}</FeatureText>
    </FeaturesList>
    <FeaturesList>
      <FeatureText>{triggeredEmails}</FeatureText>
    </FeaturesList>
  </UltimatePlanFeaturesContainer>
)

export default UltimateList
