import React, { useState, memo } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { DataElementLocations } from '@eig-builder/core-utils/helpers/tagging-helper'
import Grid from '@eig-builder/core-ui/Grid'
import Paper from '@eig-builder/core-ui/Paper'
import Button from '@eig-builder/core-ui/Button'

import SliderComponent from './components/Slider'
import CalcInfo from './components/CalcInfo'
import PriceInfo from './components/PriceInfo'
import './lang'
import { useEffect } from 'react'

const Shadow = styled.div`
  box-shadow: 0px 0px 29px 1px rgba(0, 0, 0, 0.23);
  display: flex;
  flex-direction: column;
`

const CTAButton = styled(Button)`
  font-family: Poppins, serif !important;
`

const PricingSliderCalculator = ({
  currency,
  title,
  subTitle,
  defaultValue,
  defaultColor,
  activeColor,
  marks,
  steps,
}) => {
  const [currentValue, setCurrentValue] = useState(defaultValue)

  useEffect(() => {
    setCurrentValue(defaultValue)
  }, [defaultValue, marks])

  return (
    <Shadow>
      <Paper square>
        <Grid container className='px-3'>
          <SliderComponent
            defaultValue={defaultValue}
            marks={marks}
            setCurrentValue={setCurrentValue}
            currentValue={currentValue}
            defaultColor={defaultColor}
            activeColor={activeColor}
            steps={steps}
            title={title}
          />
          <CalcInfo
            value={currentValue}
            subTitle={subTitle}
            color={activeColor}
          />
        </Grid>
      </Paper>
      <Paper square className='mt-2' elevation={0}>
        <Grid
          container
          direction='row'
          justify='space-between'
          alignItems='center'
          className='p-4'
        >
          <PriceInfo
            color={activeColor}
            currency={currency}
            marks={marks}
            value={currentValue}
          />
          <CTASection />
        </Grid>
      </Paper>
    </Shadow>
  )
}

const CTASection = () => (
  <Grid container direction='row' justify='flex-end' alignItems='center' xs={6}>
    <Grid item>
      <CTAButton
        dataElementLocation={DataElementLocations.BODY}
        dataElementLabel='plans.pricingDropdown.cta'
        dataElementId='creativemail-plans-pricing-dropdown-cta'
        href={process.env.GATSBY_PLUGIN_URL}
        color='cta'
      >
        Start Free
      </CTAButton>
    </Grid>
  </Grid>
)

PricingSliderCalculator.propTypes = {
  title: PropTypes.string.isRequired,
  currency: PropTypes.string,
  subTitle: PropTypes.string.isRequired,
  marks: PropTypes.array.isRequired,
  defaultValue: PropTypes.number,
  defaultColor: PropTypes.string,
  activeColor: PropTypes.string,
  steps: PropTypes.number,
}

PricingSliderCalculator.defaultProps = {
  currency: '$',
  defaultValue: 20,
  steps: null,
}

export default memo(PricingSliderCalculator)
