import styled from 'styled-components'

import { theme, media } from './../../helpers/theme'

const ContactsSection = styled.div`
  max-width: ${theme.breakpoints['xl']}px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: ${theme.space[12]}px;
`

const Slider = styled.div`
  display: none;

  ${media('sm')} {
    padding: 0 ${theme.space[8]}px;
    display: block;
  }

  ${media('xl')} {
    padding: 0;
  }
`

const TextContent = styled.div`
  text-align: center;
  width: 100%;
  color: ${theme.colors.font.lightGray};

  margin-bottom: ${theme.space[16]}px;

  font-size: ${theme.font.xl};

  padding: 0 ${theme.space[4]}px;

  ${media('sm')} {
    padding: 0;
    margin-bottom: ${theme.space[16]}px;
  }
`

const Title = styled.span`
  span {
    color: ${theme.colors.font.black};
    font-weight: bold;
  }
`

const Dropdown = styled.div`
  display: block;
  padding: ${theme.space[4]}px;

  ${media('sm')} {
    display: none;
  }
`

const CTA = styled.a`
  background-color: ${theme.colors.black};
  border: none;
  border-radius: 5px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  color: ${theme.colors.font.white};
  padding: ${theme.space[4]}px;
  text-transform: uppercase;
  text-decoration: none;
  white-space: nowrap;

  font-size: ${theme.font['xs']};

  ${media('sm')} {
    font-size: ${theme.font['sm']};
  }
`

const Free = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: ${theme.space[8]}px;
`

ContactsSection.slider = Slider
ContactsSection.textContent = TextContent
ContactsSection.title = Title
ContactsSection.dropdown = Dropdown
ContactsSection.cta = CTA
ContactsSection.free = Free

export default ContactsSection
