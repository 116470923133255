import React from 'react'

import { AwesomeHeader, FreeHeader, UltimateHeader } from './../../pricing-card'

import { theme } from './../../../helpers/theme'

import { Column } from './../pricing-section.styles'
import SmallView from './small-view.styles'

const Small = ({ className, selectedPlan, setSelectedPlan }) => {
  return (
    <SmallView className={className}>
      <Column padding={`${theme.space[1]}px`} justifyContent='flex-end'>
        <UltimateHeader
          border
          selectedPlan={selectedPlan}
          setSelectedPlan={setSelectedPlan}
        />
        <SmallView.ultimateList
          isActive={selectedPlan === 'ultimate'}
          setSelectedPlan={() => setSelectedPlan('ultimate')}
        />
      </Column>
      <Column padding={`${theme.space[1]}px`} justifyContent='flex-end'>
        <AwesomeHeader
          border
          selectedPlan={selectedPlan}
          setSelectedPlan={setSelectedPlan}
        />
        <SmallView.awesomeList
          isActive={selectedPlan === 'awesome'}
          setSelectedPlan={() => setSelectedPlan('awesome')}
        />
      </Column>
      <Column padding={`${theme.space[1]}px`} justifyContent='flex-end'>
        <FreeHeader
          border
          selectedPlan={selectedPlan}
          setSelectedPlan={setSelectedPlan}
        />
        <SmallView.freeList
          isActive={selectedPlan === 'free'}
          setSelectedPlan={() => setSelectedPlan('free')}
        />
      </Column>
    </SmallView>
  )
}

export default Small
