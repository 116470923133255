import React from 'react'
import styled from 'styled-components'
import { grid, position } from 'styled-system'

import { theme } from '../../../helpers/theme'
import { FREE_PLAN_HEADER } from './../../pricing-section/pricing-section.constants'

import PricingCard from '../pricing-card.styles'

const FreeHeader = ({
  border,
  className,
  selectedPlan,
  setSelectedPlan = () => {},
}) => {
  const { plan, info } = FREE_PLAN_HEADER
  const [title] = info
  const value = plan.toLowerCase()
  const isActive = selectedPlan === value

  return (
    <PricingCard
      border={border}
      className={className}
      isActive={isActive}
      onClick={() => setSelectedPlan(value)}
    >
      <PricingCard.header>
        <PricingCard.planTitle>{plan}</PricingCard.planTitle>
        <PricingCard.infoTitle fontSize={theme.font.base}>
          {title}
        </PricingCard.infoTitle>
        <PricingCard.topFeatures>Top Features</PricingCard.topFeatures>
      </PricingCard.header>
    </PricingCard>
  )
}

const StyledFreeHeader = styled(FreeHeader)`
  border-bottom: 1.35504px solid rgba(0, 0, 0, 0.05);
  justify-content: flex-start;
  grid-column: 4;
  grid-row: 3 / 4;
  padding-top: 30px;

  ${grid}
  ${position}
`

export default StyledFreeHeader
