import React from 'react'
import PropTypes from 'prop-types'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import {
  ALL_MARKETING_FEATURES,
  EXPANDED_MARKETING_FEATURES,
} from './../pricing-section.constants'

import { FeatureList } from './../../pricing-card'
import {
  AwesomeHeader,
  AwesomeDesktopList,
  FreeHeader,
  FreeDesktopList,
  UltimateHeader,
  UltimateDesktopList,
} from './../../pricing-card'
import PricingSection from './../pricing-section.styles'
import { DesktopFeaturesContainer } from './../../pricing-card/feature-list/feature-list.styles'

// const Button = styled.button`
//   appearance: none;
//   cursor: pointer;
//   background-color: ${theme.colors.black};
//   border: none;
//   border-radius: 5px;
//   box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
//     0 2px 4px -1px rgba(0, 0, 0, 0.06);
//   color: ${theme.colors.font.white};
//   padding: ${theme.space[4]}px;
//   text-transform: uppercase;
//   text-decoration: none;
//   white-space: nowrap;
//   text-align: center;

//   font-size: ${theme.font['xs']};

//   ${media('sm')} {
//     font-size: ${theme.font['sm']};
//   }
// `

const PricingSectionComponent = ({
  className,
  selectedPlan,
  setSelectedPlan,
  expanded,
  setExpanded,
  pricingRef,
  viewRef,
}) => {
  const marketingFeatures = expanded
    ? EXPANDED_MARKETING_FEATURES
    : ALL_MARKETING_FEATURES

  return (
    <PricingSection className={className} ref={viewRef}>
      <PricingSection.featuresWrapper gridColumn='1 / span 4' gridRow={4}>
        <DesktopFeaturesContainer
          isMarketingColumn
          expanded={expanded}
          rows={marketingFeatures.length}
          _noPointer
        >
          <PricingSection.tableToggle>
            <PricingSection.titleSpan />
            <PricingSection.emailMarketingTitle
              expanded={expanded}
              onClick={() => setExpanded(!expanded)}
              ref={pricingRef}
            >
              <ExpandMoreIcon size='inherit' />
              Compare All Features
            </PricingSection.emailMarketingTitle>
          </PricingSection.tableToggle>
          {expanded && <FeatureList features={marketingFeatures} />}
          {/* {!expanded && (
            <div style={{ display: 'flex', padding: theme.space[2] }}>
              <Button onClick={() => setExpanded((e) => !e)}>Show More</Button>
            </div>
          )} */}
        </DesktopFeaturesContainer>
      </PricingSection.featuresWrapper>
      <UltimateHeader
        border
        zIndex={2}
        selectedPlan={selectedPlan}
        setSelectedPlan={setSelectedPlan}
      />
      <UltimateDesktopList
        gridColumn={2}
        zIndex={2}
        gridRow={4}
        expanded={expanded}
        isActive={selectedPlan === 'ultimate'}
        setSelectedPlan={() => setSelectedPlan('ultimate')}
      />
      <AwesomeHeader
        border
        zIndex={2}
        selectedPlan={selectedPlan}
        setSelectedPlan={setSelectedPlan}
      />
      <AwesomeDesktopList
        gridColumn={3}
        gridRow={4}
        zIndex={2}
        expanded={expanded}
        isActive={selectedPlan === 'awesome'}
        setSelectedPlan={() => setSelectedPlan('awesome')}
      />
      <FreeHeader
        border
        zIndex={2}
        selectedPlan={selectedPlan}
        setSelectedPlan={setSelectedPlan}
      />
      <FreeDesktopList
        gridColumn={4}
        gridRow={4}
        zIndex={2}
        expanded={expanded}
        isActive={selectedPlan === 'free'}
        setSelectedPlan={() => setSelectedPlan('free')}
      />
    </PricingSection>
  )
}

PricingSectionComponent.props = {
  children: PropTypes.node,
}

export default PricingSectionComponent
