import styled from 'styled-components'

import {
  AwesomeSmallList,
  FreeSmallList,
  UltimateSmallList
} from './../../pricing-card'

const SmallView = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
`

SmallView.ultimateList = styled(UltimateSmallList)`
  width: 100%;
`

SmallView.awesomeList = styled(AwesomeSmallList)`
  width: 100%;
`

SmallView.freeList = styled(FreeSmallList)`
  width: 100%;
`

export default SmallView
