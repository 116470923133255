import React from 'react'
import styled from 'styled-components'

import { AWESOME_PLAN_FEATURES_MOBILE } from '../../pricing-section/pricing-section.constants'

import {
  FeaturesContainer,
  FeaturesList,
  FeatureText,
} from '../pricing-card.styles'

const [
  intelligentEmailEditor,
  wordPressBlobAndProduct,
  woocomerceIntegration,
  contactsCrm,
  campaignScheduler,
  phoneSupport,
  multistepAutomation,
  contacts,
] = AWESOME_PLAN_FEATURES_MOBILE

const AwesomeFeaturesContainer = styled(FeaturesContainer)`
  ${FeaturesList} {
    justify-content: center;
  }
`

const AwesomeList = ({ className, setSelectedPlan = () => {}, isActive }) => (
  <AwesomeFeaturesContainer
    border
    className={`${className} ${isActive ? 'active' : ''}`}
    isActive={isActive}
    onClick={setSelectedPlan}
  >
    <FeaturesList>
      <FeatureText>{intelligentEmailEditor}</FeatureText>
    </FeaturesList>
    <FeaturesList>
      <FeatureText>{wordPressBlobAndProduct}</FeatureText>
    </FeaturesList>
    <FeaturesList>
      <FeatureText _comingSoonBefore>{woocomerceIntegration}</FeatureText>
    </FeaturesList>
    <FeaturesList>
      <FeatureText>{contactsCrm}</FeatureText>
    </FeaturesList>
    <FeaturesList>
      <FeatureText>{campaignScheduler}</FeatureText>
    </FeaturesList>
    <FeaturesList>
      <FeatureText>{contacts}</FeatureText>
    </FeaturesList>
    <FeaturesList>
      <FeatureText>{phoneSupport}</FeatureText>
    </FeaturesList>
    <FeaturesList>
      <FeatureText>{multistepAutomation}</FeatureText>
    </FeaturesList>
  </AwesomeFeaturesContainer>
)

export default AwesomeList
