import styled from 'styled-components'

import { media } from './../../helpers/theme'

const Footer = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: grid;
  overflow: hidden;
  position: relative;
  width: 100%;
  ${media('xs')} {
    grid-template-rows: 131px 65px 65px minmax(250px, 1fr);
  }
  grid-template-rows: 50px 49px 49px minmax(250px, 1fr);
`

Footer.wave = styled.img`
  display: none;
  grid-row: 1 / 3;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  ${media('sm')} {
    display: block;
    min-width: 1920px;
  }
`

Footer.mobileWave = styled.img`
  display: block;
  grid-row: 1 / 3;
  left: 0;
  min-width: 375px;
  position: absolute;
  top: 0;
  width: 100%;
  ${media('sm')} {
    display: none;
  }
`

Footer.linkSection = styled.div`
  display: flex;
  align-self: flex-start;
  flex-direction: row;
  justify-content: center;
  ${media('lg')} {
    grid-row: 3 / 4;
  }
  grid-row: 4;
`

export default Footer
