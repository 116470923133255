export const DEFAULT_SLIDER_VALUE = 2000

export const SLIDER_STEPS = 500

export const ULTIMATE_BILLING_DATA = [
  { id: 1, limit: 100, price: 10.95 },
  { id: 2, limit: 500, price: 14.95 },
  { id: 3, limit: 1000, price: 18.95 },
  { id: 4, limit: 1500, price: 21.95 },
  { id: 5, limit: 2000, price: 29.95 },
  { id: 6, limit: 3000, price: 36.95 },
  { id: 7, limit: 4000, price: 44.95 },
  { id: 8, limit: 5500, price: 59.95 },
  { id: 9, limit: 7500, price: 74.95 },
  { id: 10, limit: 1000, price: 96.95 },
  { id: 11, limit: 12500, price: 119.95 },
  { id: 12, limit: 17500, price: '139.00' },
  { id: 13, limit: 22500, price: '164.00' },
  { id: 14, limit: 27500, price: '194.00' },
  { id: 15, limit: 37500, price: '249.00' },
  { id: 16, limit: 50000, price: '329.00' },
  { id: 17, limit: 75000, price: '399.00' },
]

export const AWESOME_BILLING_DATA = [
  { id: 1, limit: 100, price: 4.95 },
  { id: 2, limit: 500, price: 10.95 },
  { id: 3, limit: 1000, price: 14.95 },
  { id: 4, limit: 1500, price: 18.95 },
  { id: 5, limit: 2000, price: 21.95 },
  { id: 6, limit: 3000, price: 29.95 },
  { id: 7, limit: 5500, price: 36.95 },
  { id: 8, limit: 7500, price: 44.95 },
  { id: 9, limit: 10000, price: 59.95 },
  { id: 10, limit: 12500, price: 74.95 },
  { id: 11, limit: 17500, price: 96.95 },
  { id: 12, limit: 22500, price: '119.00' },
  { id: 13, limit: 27500, price: '140.00' },
]
