import React from 'react'
import styled from 'styled-components'
import { grid, position } from 'styled-system'

import { ULTIMATE_PLAN_HEADER } from './../../pricing-section/pricing-section.constants'
import { getCurrencySymbol } from 'helpers/currency-helper'

import PricingCard from '../pricing-card.styles'

const UltimateHeader = ({
  border,
  className,
  selectedPlan,
  setSelectedPlan = () => {},
}) => {
  const { plan, info } = ULTIMATE_PLAN_HEADER
  const [title, price] = info
  const [dollars, cents] = price.split('.')
  const value = plan.toLowerCase()
  const isActive = selectedPlan === value

  return (
    <PricingCard
      border={border}
      className={className}
      isActive={isActive}
      onClick={() => setSelectedPlan(value)}
    >
      <PricingCard.header>
        <PricingCard.planTitle>{plan}</PricingCard.planTitle>
        <PricingCard.infoTitle>{title}</PricingCard.infoTitle>
        <PricingCard.priceSection isActive={isActive}>
        <PricingCard.currency>{getCurrencySymbol().symbol}</PricingCard.currency>
          <PricingCard.priceContainer>
            <PricingCard.price>
              <PricingCard.priceDollars>{dollars}</PricingCard.priceDollars>
              <PricingCard.priceCents className='price-cents'>.{cents}</PricingCard.priceCents>
            </PricingCard.price>
          </PricingCard.priceContainer>
        </PricingCard.priceSection>
        <PricingCard.topFeatures>Top Features</PricingCard.topFeatures>
      </PricingCard.header>
    </PricingCard>
  )
}

const StyledUltimateHeader = styled(UltimateHeader)`
  border-bottom: 1.35504px solid rgba(0, 0, 0, 0.05);
  justify-content: flex-start;
  grid-column: 2;
  grid-row: 2 / 4;
  padding-top: 30px;

  ${grid}
  ${position}
`

export default StyledUltimateHeader
