import React from 'react'

import { StyledSwipeableViews } from './mobile.styles'
import { Column } from './../pricing-section.styles'

import { PLAN_TYPES } from './../../pricing-section/pricing-section.constants'

const Mobile = ({ className, selectedPlan, setSelectedPlan }) => {
  return (
    <StyledSwipeableViews
      className={className}
      index={0}
      enableMouseEvents
      onChangeIndex={(idx) => setSelectedPlan(PLAN_TYPES[idx])}
      threshold={2}
      hysteresis={1}
    >
      <Column
        className={`left-card ${selectedPlan === 'ultimate' ? 'active' : ''}`}
        margin='0 auto'
        height='calc(100% - 50px)'
        justifyContent='flex-end'
      >
        <StyledSwipeableViews.ultimateHeader
          border
          selectedPlan={selectedPlan}
        />
        <StyledSwipeableViews.ultimateList
          isActive={selectedPlan === 'ultimate'}
        />
      </Column>
      <Column
        className={`center-card ${selectedPlan === 'awesome' ? 'active' : ''} ${
          selectedPlan === 'ultimate' ? 'left-active' : ''
        }`}
        margin='0 auto'
        height='calc(100% - 50px)'
        justifyContent='flex-end'
      >
        <StyledSwipeableViews.awesomeHeader
          border
          selectedPlan={selectedPlan}
        />
        <StyledSwipeableViews.awesomeList
          isActive={selectedPlan === 'awesome'}
        />
      </Column>
      <Column
        className={`right-card ${selectedPlan === 'free' ? 'active' : ''}`}
        display='flex'
        height='calc(100% - 50px)'
        margin='0 auto 50px'
        justifyContent='flex-end'
      >
        <StyledSwipeableViews.freeHeader border selectedPlan={selectedPlan} />
        <StyledSwipeableViews.freeList isActive={selectedPlan === 'free'} />
      </Column>
    </StyledSwipeableViews>
  )
}

export default Mobile
