import React, { useState, useEffect } from 'react'
import { LocalizationContainer } from '@eig-builder/module-localization'

import { getCurrencySymbol } from 'helpers/currency-helper'
import { theme } from './../../helpers/theme'
import PricingSlider from './../pricing-slider'
import PricingDropdown from './../pricing-dropdown'
import { DataElementLocations } from '@eig-builder/core-utils/helpers/tagging-helper'

import {
  ULTIMATE_BILLING_DATA,
  AWESOME_BILLING_DATA,
} from './pricing-slider.constants'
import Layout from './contacts-section.styles'

const getMarks = (prices) => {
  return prices.map((item, index) => {
    if (index === 0 || index === prices.length - 1) {
      return {
        value: item.limit,
        price: item.price,
        label: item.limit.toLocaleString(),
      }
    }
    return {
      value: item.limit,
      price: item.price,
    }
  })
}

const ContactsSection = ({ selectedPlan }) => {
  const [marks, setMarks] = useState(getMarks(AWESOME_BILLING_DATA))

  useEffect(() => {
    if (selectedPlan === 'ultimate') {
      setMarks(getMarks(ULTIMATE_BILLING_DATA))
    } else if (selectedPlan === 'awesome') {
      setMarks(getMarks(AWESOME_BILLING_DATA))
    }
  }, [selectedPlan])

  if (selectedPlan === 'free')
    return (
      <Layout id='calculate'>
        <Layout.free>
          <Layout.cta
            href={process.env.GATSBY_PLUGIN_URL}
            data-element-location={DataElementLocations.BODY}
            data-element-label='plans.body.ctaFree'
            data-element-id='creativemail-plans-pricing-free-cta'
          >
            Start Marketing With Email
          </Layout.cta>
        </Layout.free>
      </Layout>
    )

  return (
    <LocalizationContainer>
      <Layout id='calculate'>
        <Layout.textContent>
          <Layout.title>
            The price of your{' '}
            <span style={{ textTransform: 'capitalize' }}>
              {selectedPlan} Plan
            </span>{' '}
            is based on the number of active contacts. <br />
            You pay as you grow.
          </Layout.title>
        </Layout.textContent>
        <Layout.slider>
          <PricingSlider
            currency={getCurrencySymbol().symbol}
            title='How many Contacts do you have?'
            subTitle='Contacts'
            defaultValue={100}
            activeColor={theme.colors.primary}
            marks={marks}
            steps={null}
          />
        </Layout.slider>
        <Layout.dropdown>
          <PricingDropdown
            defaultValue={100}
            activeColor={theme.colors.primary}
            marks={marks}
          />
        </Layout.dropdown>
      </Layout>
    </LocalizationContainer>
  )
}

export default ContactsSection
