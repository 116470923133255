import styled from 'styled-components'
import { grid } from 'styled-system'
import { withElements } from './../../../helpers/withElements'

import { theme } from './../../../helpers/theme'

import {
  FeaturesContainer,
  FeaturesList,
  DesktopFeaturesGrid
} from './../pricing-card.styles'

export const UnExpandedFeaturesList = styled(FeaturesList)`
  flex-direction: column;
  color: #000;
  justify-content: space-around !important;
  text-align: center;
  font-size: ${theme.font.md};
`

export const DesktopFeaturesContainer = styled(
  FeaturesContainer
).attrs((props) => ({ className: withElements(props) }))`
  ${DesktopFeaturesGrid}

  ${grid}

  ${({ expanded, rows }) =>
    expanded
      ? `grid-template-rows: minmax(350px, 1fr) repeat(${rows}, 45px) 25px;`
      : `
        grid-template-rows: minmax(350px, 1fr);
      `}
      
  ${UnExpandedFeaturesList} {
    ${({ isActive }) =>
      isActive
        ? `
          color: #FFF;
        `
        : ''}
  }

  &._noPointer {
    cursor: unset;
  }
`
