import React, { memo } from 'react'
import PropTypes from 'prop-types'

import { Item, Content, SubTitle, TotalAmount } from '../views'

const CalcInfo = ({ subTitle, value, color }) => {
  return (
    <Item item xs={2} alignSelf>
      <Content>
        <SubTitle variant='h5'>{subTitle}</SubTitle>
        <TotalAmount variant='h2' color={color}>
          {value === 0 ? '100' : value?.toLocaleString()}
        </TotalAmount>
      </Content>
    </Item>
  )
}

CalcInfo.propTypes = {
  value: PropTypes.number.isRequired,
  subTitle: PropTypes.string.isRequired,
  color: PropTypes.number.isRequired,
}

export default memo(CalcInfo)
