import styled from 'styled-components'
import Button from '@eig-builder/core-ui/Button'
import Typography from '@eig-builder/core-ui/Typography'
import { theme } from './../../helpers/theme'
import chevron from './assets/Icon.svg'

const PricingDropdown = styled.div`
  box-shadow: 0px 0px 29px 1px rgba(0, 0, 0, 0.23);
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: ${theme.space[4]}px;
`

const Title = styled.span`
  color: ${theme.colors.font.darkGray};
  font-size: ${theme.font.xl};
  margin-bottom: ${theme.space[8]}px;
`

const Select = styled.select`
  width: 100%;
  padding: ${theme.space[4]}px;
  font-size: ${theme.font.lg};

  margin-bottom: ${theme.space[8]}px;

  border: 1px solid ${theme.colors.font.lightGray};
  border-radius: 4px;
  appearance: none;

  background-color: white;
  background-image: url(${chevron});
  background-repeat: no-repeat;
  background-position: right 0.7em top 50%;

  &:focus,
  &:active {
    outline-color: ${theme.colors.primary};
  }
`

const PriceContent = styled.div`
  display: flex;
  align-items: center;
  color: ${theme.colors.font.lightGray};

  margin-bottom: ${theme.space[4]}px;
`

const CTA = styled(Button).attrs({ color: 'cta' })`
  margin-bottom: ${theme.space[4]}px !important;
`

// styles taken from slider component
const Price = styled(Typography)`
  margin-left: ${theme.space[2]}px !important;
  color: ${theme.colors.primary};
  font-size: 28px !important;
  font-weight: bold !important;
`

const Monthly = styled(Typography)`
  margin-top: ${(props) => props.theme.spacing(0.5)}px !important;
`

const Asterisk = styled(Typography)`
  margin-top: ${(props) => props.theme.spacing(-1.5)}px !important;
  margin-right: ${theme.space[2]}px !important;
`

PricingDropdown.title = Title
PricingDropdown.select = Select
PricingDropdown.priceContent = PriceContent
PricingDropdown.cta = CTA
PricingDropdown.price = Price
PricingDropdown.monthly = Monthly
PricingDropdown.asterisk = Asterisk

export default PricingDropdown
