import React from 'react'

import { ULTIMATE_PLANS_FEATURES } from './../../pricing-section/pricing-section.constants'

import { FeatureText } from './../pricing-card.styles'
import {
  DesktopFeaturesContainer,
  UnExpandedFeaturesList,
} from './feature-list.styles'

const UltimateSmallList = ({
  setSelectedPlan = () => {},
  isActive,
  ...rest
}) => {
  return (
    <DesktopFeaturesContainer
      border
      isActive={isActive}
      onClick={setSelectedPlan}
      rows={1}
      {...rest}
    >
      <UnExpandedFeaturesList>
        {ULTIMATE_PLANS_FEATURES.map(({ label, modifiers }) => (
          <FeatureText key={label} {...modifiers}>
            {label}
          </FeatureText>
        ))}
      </UnExpandedFeaturesList>
    </DesktopFeaturesContainer>
  )
}

export default UltimateSmallList
