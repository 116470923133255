import React from 'react'

import {
  FREE_PLANS_FEATURES_EXPANDED,
  FREE_PLANS_FEATURES
} from '../../pricing-section/pricing-section.constants'

import { FeaturesList, FeatureText, Done, Close } from '../pricing-card.styles'
import {
  DesktopFeaturesContainer,
  UnExpandedFeaturesList
} from './feature-list.styles'

const FreeDesktopList = ({
  setSelectedPlan = () => {},
  isActive,
  expanded = true,
  ...rest
}) => {
  const features = expanded ? FREE_PLANS_FEATURES_EXPANDED : ['']
  return (
    <DesktopFeaturesContainer
      border
      isActive={isActive}
      onClick={setSelectedPlan}
      rows={features.length}
      expanded={expanded}
      {...rest}
    >
      <UnExpandedFeaturesList>
        {FREE_PLANS_FEATURES.map((feature) => (
          <FeatureText key={feature}>{feature}</FeatureText>
        ))}
      </UnExpandedFeaturesList>
      {expanded &&
        features.map((feature, i) => (
          <FeaturesList
            key={`free-${feature}-${i}`}
            header={feature === null}
            isActive={isActive}
          >
            <FeatureText>
              {feature === true ? (
                <Done />
              ) : feature === false ? (
                <Close isActive={isActive} />
              ) : (
                feature
              )}
            </FeatureText>
          </FeaturesList>
        ))}
    </DesktopFeaturesContainer>
  )
}

export default FreeDesktopList
