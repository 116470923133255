import styled, { css } from 'styled-components'
import { Close as close, Done as done } from '@material-ui/icons'
import { typography, flexbox, grid, position } from 'styled-system'

import { theme } from '../../helpers/theme'
import { withElements } from '../../helpers/withElements'
import { getCurrencySymbol } from 'helpers/currency-helper'

const FeatureActiveState = css`
  ${({ isActive }) =>
    isActive
      ? `
          background-color: ${theme.colors.darkPurple};
          color: ${theme.colors.font.white};
        `
      : `
          background-color: ${theme.colors.lightGray};
          color: ${theme.colors.darkPurple}; 
        `}

  ${({ isMarketingColumn }) =>
    isMarketingColumn
      ? `
      grid-template-columns: minmax(0, 1fr);
      background-image: linear-gradient(to right, transparent 35%, #F9F9F9 35%);
      background-color: transparent;
      color: ${theme.colors.font.lightGray};
      .list-item {
        font-weight: normal;
      }
    `
      : ''}
`

const FeatureActiveCard = css`
  ${({ isActive }) =>
    isActive
      ? `
        position: relative;
        box-shadow: unset;
        &:after {
          content: '';
          position: absolute;
          transform: translate(0, calc(100% + 2px));
          bottom: 0;
          left: calc(50% - 60px);
          width: 0;
          height: 0;
          border-left: 60px solid transparent;
          border-right: 60px solid transparent;
          border-top: 30px solid ${theme.colors.primary};
        }
        `
      : ''}
`

const FeatureBorder = css`
  ${({ border }) =>
    border
      ? `
      border-radius: 0px 0px 4px 4px;
      border: 3px solid rgba(0, 0, 0, 0.1);
      box-sizing: border-box;
      border-top: unset;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      box-shadow: 0px 3px 2px 2px rgba(143, 142, 135, 0.1);
      `
      : ``}
`

export const HeaderActiveBorder = css`
  ${({ isActive }) =>
    isActive
      ? `
        border: 3px solid ${theme.colors.primary};
        border-bottom: unset;
        box-shadow: unset;
        background-color: ${theme.colors.primary};
        &::after {
          content: 0;
          border-bottom: 1.35504px solid rgba(0, 0, 0, 0.05);
        }
        `
      : ''}
`

export const HeaderBorder = css`
  ${({ border }) =>
    border
      ? `
        border-radius: 4px 4px 0px 0px;
        border: 3px solid rgba(0, 0, 0, 0.1);
        box-sizing: border-box;
        border-bottom: unset !important;
        box-shadow: 0px 0px 2px 2px rgba(143, 142, 135, 0.1);
        `
      : ``}
`

const PriceActiveState = css`
  background-color: white;
  color: ${theme.colors.font.black};
  .price-cents {
    &::after {
      content: '/mo';
      padding-left: ${theme.space[1]}px;
      color: ${theme.colors.font.lightGray};
      font-weight: normal;
      font-size: ${theme.font.base};
    }
  }
`

const PriceInactiveState = css`
  background-color: ${theme.colors.primary};
  color: ${theme.colors.font.white};
  .price-cents {
    &::after {
      content: '/mo';
      padding: 0 ${theme.space[1]}px;
      color: ${theme.colors.font.white};
      font-weight: normal;
      font-size: ${theme.font.base};
    }
  }
`

const PricingCard = styled.div.attrs(({ isActive }) => ({
  role: 'button',
  'aria-pressed': isActive,
}))`
  display: flex;
  cursor: pointer;
  flex-direction: column;

  padding: ${theme.space[1]}px;
  width: 100%;
  ${({ isActive }) =>
    isActive
      ? `
          color: ${theme.colors.font.white};
          `
      : `
          color: ${theme.colors.font.black};
          background-color: white;
        `}

  ${HeaderBorder}
  ${HeaderActiveBorder}

`

// HEADER COMPONENT STYLES
PricingCard.header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
`

PricingCard.promotedPlan = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: ${theme.colors.accent};
  color: ${theme.colors.font.black};
  width: 100%;
  height: 50px;
  margin-bottom: 30px;
`

PricingCard.promotedText = styled.span`
  font-size: ${theme.font['2xl']};
  font-weight: bold;
`

PricingCard.planTitle = styled.span`
  font-size: ${theme.font['3xl']};
  font-weight: bold;
`

PricingCard.infoSection = styled.div`
  display: grid;
  grid-template-rows: 15px 40px 15px;
`

PricingCard.info = styled.div`
  grid-row: 3;
`

PricingCard.infoTitle = styled.div`
  grid-row: 2;
  display: flex;
  align-self: center;
  font-size: ${theme.font.sm};
  padding-bottom: ${theme.space[2]}px;
  text-align: center;
  margin: auto;
  ${typography}
`

PricingCard.priceSection = styled.div`
  border-radius: 20px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  font-size: ${theme.font.lg};
  padding: ${theme.space[4]}px;
  ${({ isActive }) => (isActive ? PriceActiveState : PriceInactiveState)}
`

PricingCard.priceContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: bold;
  grid-row: 3;
  justify-content: center;
  position: relative;
`

PricingCard.price = styled.div`
  display: flex;
  flex-wrap: nowrap;
`

PricingCard.priceDollars = styled.div`
  font-size: ${theme.font['4xl']};
  line-height: 2.4rem;
  align-self: flex-end;
`

PricingCard.currency = styled.div`
  font-size: ${theme.font.base};
`

PricingCard.priceCents = styled.div`
  display: flex;
  align-self: flex-end;
  font-size: ${theme.font.base};
`

PricingCard.originalPrice = styled.div`
  grid-row: 4;
  text-decoration: line-through;
  padding: ${theme.space[2]}px 0;
  &::before {
    content: '${getCurrencySymbol().symbol} ';
  }
  &::after {
    content: ' /mo';
  }
`
PricingCard.topFeatures = styled.span`
  padding: ${theme.space[3]}px 0 0 ${theme.space[2]}px;
  font-size: ${theme.font.lg};
  font-weight: bolder;
`

// FEATURE LIST COMPONENT STYLES
export const FeaturesList = styled.div.attrs((props) => ({
  className: withElements(props),
}))`
  ${({ header, isActive }) => {
    if (header) {
      if (isActive) {
        return `
          background-color: #9b7eba;
          margin: 0 -3px;
          `
      } else {
        return 'background-color: #f5f5f5;'
      }
    } else {
      return ''
    }
  }}
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 ${theme.space[2]}px;
  font-weight: 500;
  justify-content: center;

  &.header-item {
    font-weight: bold !important;
  }

  ${flexbox}
`

export const Close = styled(close)`
  ${({ isActive }) =>
    isActive ? 'color: rgba(255, 255, 255, 0.5)' : 'color: rgba(0, 0, 0, 0.15)'}
`

export const Done = styled(done)``

export const FeatureText = styled.span.attrs((props) => ({
  className: withElements(props),
}))`
  &._comingSoon {
    &:after {
      display: inline-block;
      content: 'Coming Soon';
      color: ${theme.colors.font.black};
      background-color: ${theme.colors.accent};
      font-size: ${theme.font.sm};
      border-radius: 999px;
      padding: 0 ${theme.space[2]}px;
      margin-left: ${theme.space[2]}px;
      font-weight: 700;
    }
  }
  &._comingSoonBefore {
    &:before {
      display: inline-flex;
      content: 'Coming Soon';
      color: ${theme.colors.font.black};
      background-color: ${theme.colors.accent};
      font-size: ${theme.font.sm};
      border-radius: 999px;
      padding: 0 ${theme.space[2]}px;
      margin-right: ${theme.space[2]}px;
      font-weight: 700;
    }
  }
`

const activeModifierColor = css`
  ${({ isActive }) =>
    `color: ${isActive ? 'rgba(255, 255, 255, 0.4);' : 'rgba(0,0,0, 0.4)'}`}
`

export const DesktopFeaturesGrid = css`
  display: grid;
  grid-template-columns: 1fr;
  ${({ rows = 7 }) => `grid-template-rows: repeat(${rows}, 45px);`}
  flex-direction: column;
  grid-auto-flow: column;
  grid-row: 2;
  width: 100%;

  ${position}
`

export const FeaturesContainer = styled.div.attrs(
  ({ isActive, className }) => ({
    className: className,
    role: 'button',
    'aria-pressed': isActive,
  })
)`
  cursor: pointer;
  font-weight: 500;

  ._perDay {
    &::after {
      content: ' /day';
      ${activeModifierColor}
    }
  }

  ._perMonth {
    &::after {
      content: ' /month';
      ${activeModifierColor}
    }
  }

  ._strikeThrough {
    text-decoration: line-through;
    ${activeModifierColor}
  }
  
  ${FeaturesList} {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    display: flex;
    height: 100%;
    align-items: center;
    ${({ justifyCenter }) => (justifyCenter ? 'justify-content: center;' : '')}
  }

${FeatureBorder}  
${FeatureActiveState}
${FeatureActiveCard}

${flexbox}
${grid}
${position}
`

export default PricingCard
