import React from 'react'

import PlansHeroLayout from './plans-hero.styles'
// import PlansDiscount from './../plans-discount'
import wave from './../../images/combined-swirls-2.svg'

const PlansHero = () => (
  <PlansHeroLayout>
    <PlansHeroLayout.wave src={wave} />
    <PlansHeroLayout.sections>
      <PlansHeroLayout.content>
        <PlansHeroLayout.contentItem>
          <PlansHeroLayout.title>
            The right plan, at the right price.
          </PlansHeroLayout.title>
          <PlansHeroLayout.subtitle>
            Start for free, upgrade when you grow.
          </PlansHeroLayout.subtitle>
        </PlansHeroLayout.contentItem>
      </PlansHeroLayout.content>
      {/* <PlansDiscount /> */}
    </PlansHeroLayout.sections>
  </PlansHeroLayout>
)

export default PlansHero
