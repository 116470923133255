import styled from 'styled-components'

import Typography from '@eig-builder/core-ui/Typography'
import Grid from '@eig-builder/core-ui/Grid'

const PoppinTypography = styled(Typography)`
  font-family: Poppins, serif !important;
`

const SubTitle = styled(PoppinTypography)`
  text-align: center;
  color: ${(props) => props.theme.palette.black.main};
`

const TotalAmount = styled(PoppinTypography)`
  text-align: center;
  padding-top: ${(props) => props.theme.spacing(4)}px;
  color: ${(props) => props.color};
`

const Title = styled(PoppinTypography)`
  padding-top: ${(props) => props.theme.spacing(4)}px;
  padding-bottom: ${(props) => props.theme.spacing(4)}px;
`

const Item = styled(Grid)`
  ${(props) =>
    props.borderRight &&
    `border-right: 1px solid ${props.theme.palette.black.lightest}`};
  ${(props) => props.alignSelf && 'align-self: center'};
`

const Content = styled.div`
  padding-top: ${(props) => props.theme.spacing(4)}px;
  padding-bottom: ${(props) => props.theme.spacing(8)}px;
`

const ThumbImage = styled.img`
  margin-top: ${(props) => props.theme.spacing(3)}px;
`

const Price = styled(PoppinTypography)`
  color: ${(props) => props.color};
  font-size: 28px !important;
  font-weight: bold !important;
`

const Monthly = styled(PoppinTypography)`
  margin-top: ${(props) => props.theme.spacing(0.5)}px !important;
`

const PerTypo = styled(PoppinTypography)`
  margin-top: ${(props) => props.theme.spacing(-1.5)}px !important;
`

export {
  SubTitle,
  TotalAmount,
  Title,
  Item,
  Content,
  ThumbImage,
  Price,
  Monthly,
  PerTypo,
  PoppinTypography
}
