import styled from 'styled-components'
import { theme, media } from './../../helpers/theme'

const PlansHero = styled.div`
  overflow: hidden;
  position: relative;
  grid-template-columns: 1fr;
  display: grid;
  z-index: 5;
  height: auto;
`

const Wave = styled.img`
  position: absolute;
  top: -1px;
  z-index: -1;
  grid-row-start: 1;
  grid-column-start: 1;
  transition: all 0.5s;

  left: 0;
  right: 0;
  width: calc(100% + 800px);
  object-fit: cover;

  ${media('sm')} {
    width: calc(100% + 440px);
  }

  ${media('md')} {
    width: calc(100% + 500px);
  }

  ${media('xl')} {
    transform: translateY(-24px);
  }

  @media screen and (min-width: 1900px) {
    transform: translateY(-90px);
  }

  ${media(3000)} {
    position: static;
    height: unset;
    width: 100%;
  }
`

const Sections = styled.div`
  display: grid;

  grid-row-start: 1;
  grid-column-start: 1;
  
  ${media(3000)} {
    grid-template-rows: 1fr 1fr;
  }
`

const Content = styled.div`
  grid-row-start: 1;
  grid-column-start: 1;
  width: 100%;
  color: ${theme.colors.font.white};

  margin: 0 auto;
  max-width: ${theme.breakpoints.xl}px;

  padding: ${theme.space[4]}px;

  margin-top: 140px;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
  display: flex;

  ${media('md')} {
    padding: ${theme.space[8]}px;
    display: grid;
    margin-top: 180px;
  }

  ${media('lg')} {
    margin-bottom: 0;
  }
`

const ContentItem = styled.div`
  flex-basis: 40%;
  flex: 1;

  flex-direction: column;
  display: flex;

  margin: 0 ${theme.space[8]}px;
`

const Title = styled.span`
  text-align: center;
  font-size: ${theme.font['3xl']};
  font-weight: bold;
  color: ${theme.colors.darkGray};

  ${media('sm')} {
    font-size: ${theme.font['4xl']};
  }

  margin-bottom: ${theme.space[6]}px;
`

const Subtitle = styled.span`
  margin-bottom: ${theme.space[6]}px;
  color: ${theme.colors.darkGray};
  text-align: center;
  font-size: ${theme.font['xl']};

`

PlansHero.wave = Wave
PlansHero.sections = Sections
PlansHero.content = Content
PlansHero.contentItem = ContentItem
PlansHero.title = Title
PlansHero.subtitle = Subtitle

export default PlansHero
