import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import Help from '@material-ui/icons/Info'
import { FeaturesList } from '../pricing-card.styles'
import P from '@eig-builder/core-ui/Popper'
import { Fade } from '@material-ui/core'
import theme from '../../../helpers/theme'

const Popper = styled(P)`
  z-index: 100;
`

const HelpIcon = styled(Help)`
  cursor: pointer;
  margin-left: ${theme.space[2]}px;
  color: black;
`

const Tooltip = styled.div`
  background-color: #313944;
  color: ${theme.colors.font.white};
  border-radius: 5px;
  padding: ${theme.space[4]}px;
  margin-left: ${theme.space[3]}px;
  max-width: 350px;
  position: relative;

  &::before {
    content: ' ';
    position: absolute;
    border-right: 10px solid #313944;
    border-bottom: 10px solid transparent;
    border-top: 10px solid transparent;
    height: 0;
    width: 0;
    transform: translateX(-10px);
    left: 0;
    top: calc(50% - 10px);
  }
`

const HeaderSpan = styled.div`
  position: absolute;
  width: 1000%;
  left: -100vw;
  height: 45px;
  background-color: #f5f5f5;
  font-weight: bold;
`

const FeatureLabel = styled.div`
  position: relative;
  display: flex;
  width: 350px;
  align-items: center;
  justify-content: space-between;
  padding-right: ${theme.space[3]}px;
  color: ${theme.colors.font.black};
`

const FeatureList = ({ features, setSelectedPlan }) =>
  features.map(({ label, header, tooltip }, index) => (
    <FeatureContent
      key={`feature-list-item-${label}-${index}`}
      header={header}
      label={label}
      tooltip={tooltip}
      setSelectedPlan={setSelectedPlan}
    />
  ))

const FeatureContent = ({ header, label, tooltip, setSelectedPlan }) => {
  const ref = useRef()
  const [open, setOpen] = useState(false)
  const showTooltip = () => setOpen(true)
  const hideTooltip = () => setOpen(false)

  return (
    <FeaturesList
      header={header}
      className={header ? 'header-item' : 'list-item'}
      onClick={setSelectedPlan}
      justifyContent='flex-start'
    >
      {header && <HeaderSpan />}
      <FeatureLabel>
        <span>{label}</span>
        {tooltip && (
          <HelpIcon
            onMouseEnter={showTooltip}
            onMouseLeave={hideTooltip}
            ref={ref}
          />
        )}
        {typeof window !== 'undefined' && (
          <Popper
            open={open}
            anchorEl={ref.current}
            placement='right'
            transition
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <Tooltip>{tooltip}</Tooltip>
              </Fade>
            )}
          </Popper>
        )}
      </FeatureLabel>
    </FeaturesList>
  )
}
export default FeatureList
